import * as React from 'react'

import {Helmet} from 'react-helmet-async'

/**
 * Use third-party fonts without blocking rendering
 * The font is prefetched anyway to transition to it as fast as possible
 */
export function ExternalFont() {
  // Using "print" initially to enforce browser to ignore stylesheet
  const [media, setMedia] = React.useState('print')

  // `useEffect` is not executed on server thus `media` will be set to `print`
  // `useEffect` is executed on client after hydration is over thus swapping
  // `media` without causing conflicts between server HTML and initial render
  // result
  React.useEffect(() => {
    // Set target media as "screen" to apply stylesheet
    setMedia('screen')
  }, [])

  return (
    <Helmet>
      <link
        rel="prefetch"
        href="https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@700&family=Noto+Sans+JP&display=swap"
        crossOrigin
      />
      <link
        href="https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@700&family=Noto+Sans+JP&display=swap"
        rel="stylesheet"
        media={media}
      />
    </Helmet>
  )
}

// For @loadable/components
export default ExternalFont
